import { loyaltyUoUModalClickSrc136Evid1103 } from '@wix/bi-logger-loyalty-data/v2';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { InputDialog, Spinner, Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../constants';
import { BirthdayState } from '../constants';
import { useControllerProps } from '../hooks';
import { classes, st } from './AddBirthdayModal.st.css';

const BirthdayPicker = lazy(() => import('./BirthdayPicker'));

const parseBirthday = (birthday?: string) => (birthday ? new Date(birthday.replace(/-/g, '/')) : undefined);

interface AddBirthdayModalProps {
  description: string;
  onClose(): void;
}

export const AddBirthdayModal: React.FC<AddBirthdayModalProps> = ({ description, onClose }) => {
  const { t } = useTranslation();
  const { onUpdateBirthday, birthday, birthdayState } = useControllerProps();
  const [selectedBirthday, setSelectedBirthday] = useState<Date | undefined>(() => parseBirthday(birthday));
  const [showError, setShowError] = useState(false);
  const bi = useBi();

  const isUpdatingBirthday = birthdayState === BirthdayState.UpdatingBirthday;
  const isLoadingModal = birthdayState === BirthdayState.ModalLoading;

  const handleSave = async () => {
    if (isUpdatingBirthday || isLoadingModal) {
      return;
    }

    if (!selectedBirthday) {
      setShowError(true);
      return;
    }

    const year = selectedBirthday.getFullYear();
    const month = (selectedBirthday.getMonth() + 1).toString().padStart(2, '0');
    const day = selectedBirthday.getDate().toString().padStart(2, '0');
    const newBirthday = `${year}-${month}-${day}`;
    onUpdateBirthday(newBirthday);
    bi.report(
      loyaltyUoUModalClickSrc136Evid1103({
        modalOrigin: 'loyalty',
        modalClick: 'save',
        modalName: 'add_birthday',
      }),
    );
  };

  const handleClose = () => {
    bi.report(
      loyaltyUoUModalClickSrc136Evid1103({
        modalOrigin: 'loyalty',
        modalClick: 'close',
        modalName: 'add_birthday',
      }),
    );

    onClose();
  };

  useEffect(() => {
    setSelectedBirthday(parseBirthday(birthday));
  }, [birthday]);

  return (
    <InputDialog
      title={t('app.add-birthday-modal.title')}
      isOpen
      onClose={handleClose}
      primaryButton={{
        text: isUpdatingBirthday ? t('app.add-birthday-modal.loading') : t('app.add-birthday-modal.save'),
        onClick: handleSave,
        disabled: isUpdatingBirthday,
      }}
      secondaryButton={{
        text: t('app.add-birthday-modal.cancel'),
        onClick: handleClose,
      }}
      withoutDivider
      data-hook={DataHook.AddBirthdayModal}
      closeButtonAriaLabel={t('app.add-birthday-modal.close')}
    >
      {isLoadingModal && <Spinner className={classes.spinner} diameter={50} isCentered />}
      <div className={st(classes.root, { isLoading: isLoadingModal })}>
        <Suspense
          fallback={
            <div className={classes.datePickerLoader}>
              <Spinner className={classes.spinner} diameter={50} isCentered />
            </div>
          }
        >
          <Text className={classes.description}>{description}</Text>
          <BirthdayPicker
            selectedBirthday={selectedBirthday}
            setSelectedBirthday={setSelectedBirthday}
            showError={showError}
          />
        </Suspense>
      </div>
    </InputDialog>
  );
};
