import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Divider, Ribbon, Text } from 'wix-ui-tpa/cssVars';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import { SimpleReward, SimpleRule, SimpleTier, SocialMediaChannel } from '../../../types/domain';
import settingsParams from '../settingsParams';
import stylesParams, { BulletStyle, PointsTextPosition } from '../stylesParams';
import { AddBirthdayButton } from './AddBirthdayButton';
import { BulletThumbnail } from './BulletThumbnail';
import { SocialMediaRule } from './SocialMediaRule';
import { classes, st } from './TierCards.st.css';

interface TierCardsProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
  currentTierId?: string;
  isLoggedIn: boolean;
  followedChannels: SocialMediaChannel[];
  formatNumber: (value: number) => string;
  onFollowSocialMediaChannel(type: SocialMediaChannel): void;
}

type DetailsItem = {
  title: string;
  description: string;
};

export const TierCards: React.FC<TierCardsProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
  currentTierId,
  isLoggedIn,
  followedChannels,
  formatNumber,
  onFollowSocialMediaChannel,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const { isViewer } = useEnvironment();

  const showTierIcon = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.tiersShowIcon);
  const showTierDescription = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.tiersShowDescription);
  const bulletThumbnailStyle = styles.get(stylesParams.tiersBulletsStyle) as BulletStyle;
  const showBulletThumbnail = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.tiersBulletsStyle !== BulletStyle.None);
  const isUsingPointsTextPosition = (position: PointsTextPosition) =>
    styles
      .getStylesForAllBreakpoints()
      .some((breakpointStyles) => breakpointStyles.numbers.tiersPointsTextPosition === position);

  const baseTier: SimpleTier = {
    title: tiersProgramSettings.baseTierDefinition?.name ?? '',
    description: tiersProgramSettings.baseTierDefinition?.description ?? '',
    iconUrl: tiersProgramSettings.baseTierDefinition?.icon?.url ?? '',
  };
  const showSocialMedia = isLoggedIn || !isViewer;
  const showBirthday = isLoggedIn || !isViewer;

  const tierTitleTag = settings.get(settingsParams.tierTitleTag);
  const tierDescriptionTag = settings.get(settingsParams.tierDescriptionTag);
  const tierPointsTextTag = settings.get(settingsParams.tierPointsTextTag);
  const tierSectionTitleTag = settings.get(settingsParams.tierSectionTitleTag);
  const tierRoleTitleTag = settings.get(settingsParams.tierRoleTitleTag);
  const tierRoleDescriptionTag = settings.get(settingsParams.tierRoleDescriptionTag);

  const renderDetailsContent = (title: string, description: string, birthdayDescription?: string) => (
    <>
      {showBulletThumbnail && (
        <div className={classes.detailBulletContainer}>
          <BulletThumbnail type={bulletThumbnailStyle} size={17} />
        </div>
      )}
      <div aria-label={t('app.tiers.role')} className={classes.detailTextContainer}>
        <Text tagName={tierRoleTitleTag} className={classes.detailTitle}>
          {title}
        </Text>
        <Text tagName={tierRoleDescriptionTag} className={classes.detailDescription}>
          {description}
        </Text>
        {birthdayDescription && <AddBirthdayButton description={birthdayDescription} />}
      </div>
    </>
  );

  const renderRewardsDetails = (rewards: DetailsItem[]) =>
    rewards.map((reward, index) => (
      <li key={index} className={classes.detailContainer}>
        {renderDetailsContent(reward.title, reward.description)}
      </li>
    ));

  const renderRulesDetails = (earningRules: SimpleRule[]) =>
    earningRules.map((rule, index) => (
      <li key={index}>
        <div className={classes.detailContainer}>
          {renderDetailsContent(
            rule.title,
            rule.description,
            rule.birthday && rule.tierId === currentTierId && showBirthday ? rule.birthday.description : undefined,
          )}
        </div>
        {rule.socialMedia && rule.tierId === currentTierId && showSocialMedia && (
          <SocialMediaRule
            followedChannels={followedChannels}
            socialMedia={rule.socialMedia}
            onFollowSocialMediaChannel={onFollowSocialMediaChannel}
          />
        )}
      </li>
    ));

  const renderRules = (tierId: string | undefined | null) => {
    const rules = simpleRules.filter((rule) => rule.tierId === tierId || (!rule.tierId && !tierId));

    if (!rules.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text tagName={tierSectionTitleTag} className={classes.sectionTitle}>
          {settings.get(settingsParams.earnPointsTitle)}
        </Text>
        <ul className={classes.list}>{renderRulesDetails(rules)}</ul>
      </div>
    );
  };

  const renderRewards = (tierId: string | undefined | null) => {
    const rewards = simpleRewards.filter((reward) => reward.tierId === tierId || (!reward.tierId && !tierId));

    if (!rewards.length) {
      return null;
    }

    return (
      <div className={classes.section}>
        <Text tagName={tierSectionTitleTag} className={classes.sectionTitle}>
          {settings.get(settingsParams.redeemPointsTitle)}
        </Text>
        <ul className={classes.list}>{renderRewardsDetails(rewards)}</ul>
      </div>
    );
  };

  const renderTierCards = () => {
    const cards = [baseTier, ...simpleTiers].map((tier, index) => {
      const showIcon = showTierIcon && !!tier.iconUrl;
      const requiredPoints = formatNumber(tier.requiredPoints ?? 0);
      const renderCurrentTierRibbon = styles
        .getStylesForAllBreakpoints()
        .some((breakpointStyles) => breakpointStyles.booleans.showCurrentTier);
      const showCurrentTierStyle = tier.id === currentTierId && (isLoggedIn || !isViewer);

      return (
        <li className={classes.tierCard} key={index}>
          {renderCurrentTierRibbon && showCurrentTierStyle && (
            <Ribbon className={classes.ribbon}>{settings.get(settingsParams.currentTierRibbon)}</Ribbon>
          )}
          <div className={st(classes.tierCardHeader, { isCurrentTier: showCurrentTierStyle })}>
            <div className={classes.tierCardTitleA11y}>
              <div className={classes.tierCardTitleContainer}>
                {showIcon && (
                  <img
                    alt={t('app.image.tier-icon')}
                    aria-hidden
                    className={classes.tierCardIcon}
                    src={IMAGE_ROUTE(tier.iconUrl)}
                  />
                )}
                <Text tagName={tierTitleTag} className={classes.tierCardTitle}>
                  {tier.title}
                </Text>
              </div>
              {isUsingPointsTextPosition(PointsTextPosition.AboveTitle) && (
                <Text tagName={tierPointsTextTag} className={classes.tierCardPointsAboveTitle}>
                  {t('app.tiers.points-needed', { requiredPoints })}
                </Text>
              )}
            </div>
            {isUsingPointsTextPosition(PointsTextPosition.UnderTitle) && (
              <Text className={classes.tierCardPointsUnderTitle}>
                {t('app.tiers.points-needed', { requiredPoints })}
              </Text>
            )}
            {showTierDescription && (
              <div className={classes.tierCardDescriptionContainer}>
                {tier.description.trim() && (
                  <Text tagName={tierDescriptionTag} className={classes.tierCardDescription}>
                    {tier.description}
                  </Text>
                )}
                {isUsingPointsTextPosition(PointsTextPosition.UnderDescription) && (
                  <Text className={classes.tierCardPointsUnderDesc}>
                    {t('app.tiers.points-needed', { requiredPoints })}
                  </Text>
                )}
              </div>
            )}
          </div>
          <Divider className={classes.tierCardDivider} />
          <div className={classes.sectionsContainer}>
            {renderRules(tier.id)}
            {renderRewards(tier.id)}
          </div>
        </li>
      );
    });

    return cards;
  };

  const tierCards = renderTierCards();

  return <ol className={classes.root}>{tierCards}</ol>;
};
