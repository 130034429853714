import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../constants';
import { SimpleReward, SimpleRule, SimpleTier, SocialMediaChannel } from '../../../types/domain';
import settingsParams from '../settingsParams';
import { TierCards } from './TierCards';
import { classes } from './TiersProgram.st.css';

interface TiersProgramProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
  currentTierId?: string;
  isLoggedIn: boolean;
  followedChannels: SocialMediaChannel[];
  formatNumber: (value: number) => string;
  onFollowSocialMediaChannel(type: SocialMediaChannel): void;
}

export const TiersProgram: React.FC<TiersProgramProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
  currentTierId,
  isLoggedIn,
  followedChannels,
  formatNumber,
  onFollowSocialMediaChannel,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const showTiersPageTitle = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showTiersPageTitle);
  const showTiersPageSubtitle = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showTiersPageSubtitle);
  const sectionTitleTag = settings.get(settingsParams.tiersSectionTitleTag);
  const sectionSubtitleTag = settings.get(settingsParams.tiersSectionSubtitleTag);

  return (
    <div className={classes.root} data-hook={DataHook.TiersProgram}>
      <div className={classes.headerSection}>
        {showTiersPageTitle && (
          <Text tagName={sectionTitleTag} className={classes.sectionTitle}>
            {settings.get(settingsParams.tiersTitle)}
          </Text>
        )}
        {showTiersPageSubtitle && (
          <Text tagName={sectionSubtitleTag} className={classes.sectionSubtitle}>
            {settings.get(settingsParams.tiersSubtitle)}
          </Text>
        )}
      </div>
      <TierCards
        {...{
          simpleTiers,
          simpleRules,
          simpleRewards,
          tiersProgramSettings,
          formatNumber,
          currentTierId,
          isLoggedIn,
          onFollowSocialMediaChannel,
          followedChannels,
        }}
      />
    </div>
  );
};
