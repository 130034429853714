import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import { defaultBackgroundImage } from '../../constants/default-background-image';

export enum HeaderImageCropPosition {
  TopLeft = 'TOP_LEFT',
  Top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export type ISettingsParams = {
  headerImage: SettingsParamType.Text; // @todo: Remove this (used as default for new image)
  headerImageNew: SettingsParamType.Text;
  headerImageCropPosition: SettingsParamType.Text;

  programName: SettingsParamType.Text;
  programNameTag: SettingsParamType.String;
  programTitle: SettingsParamType.Text;
  programTitleTag: SettingsParamType.String;
  buttonAnonymous: SettingsParamType.Text;
  buttonLoggedIn: SettingsParamType.Text;

  detailsStepIndicationTag: SettingsParamType.String;
  detailsStepTag: SettingsParamType.String;
  detailsTitleTag: SettingsParamType.String;
  detailsDescriptionTag: SettingsParamType.String;
  tiersSectionTitleTag: SettingsParamType.String;
  tiersSectionSubtitleTag: SettingsParamType.String;
  tierTitleTag: SettingsParamType.String;
  tierDescriptionTag: SettingsParamType.String;
  tierPointsTextTag: SettingsParamType.String;
  tierSectionTitleTag: SettingsParamType.String;
  tierRoleTitleTag: SettingsParamType.String;
  tierRoleDescriptionTag: SettingsParamType.String;

  signUpTitle: SettingsParamType.Text;
  earnPointsTitle: SettingsParamType.Text;
  redeemPointsTitle: SettingsParamType.Text;
  signUpDescription: SettingsParamType.Text;
  earnPointsDescription: SettingsParamType.Text;
  redeemPointsDescription: SettingsParamType.Text;

  tiersTitle: SettingsParamType.Text;
  tiersSubtitle: SettingsParamType.Text;
  currentTierRibbon: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  headerImage: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  headerImageNew: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ getSettingParamValue }) => {
      const image = getSettingParamValue({
        type: SettingsParamType.Text,
        key: 'headerImage',
        getDefaultValue: () => '',
      });
      return image || defaultBackgroundImage;
    },
  },
  headerImageCropPosition: {
    type: SettingsParamType.Text,
    getDefaultValue: () => HeaderImageCropPosition.Middle,
  },
  programName: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  programNameTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h1',
  },
  programTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => {
      return t('app.settings.defaults.program-title-new');
    },
  },
  programTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  buttonAnonymous: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-anonymous'),
  },
  buttonLoggedIn: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-logged-in'),
  },

  detailsStepIndicationTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  detailsStepTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h2',
  },
  detailsTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  detailsDescriptionTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tiersSectionTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h2',
  },
  tiersSectionSubtitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tierTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'h3',
  },
  tierDescriptionTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tierPointsTextTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tierSectionTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tierRoleTitleTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },
  tierRoleDescriptionTag: {
    type: SettingsParamType.String,
    getDefaultValue: () => 'p',
  },

  signUpTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.sign-up-title'),
  },
  earnPointsTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.earn-points-title'),
  },
  redeemPointsTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.redeem-points-title'),
  },
  signUpDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.sign-up-description'),
  },
  earnPointsDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.earn-points-description'),
  },
  redeemPointsDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.redeem-points-description'),
  },

  tiersTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.program-title'),
  },
  tiersSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.program-subtitle'),
  },
  currentTierRibbon: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.tiers.current-tier-ribbon'),
  },
});
