import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../constants';
import { Content } from './Content';
import { classes } from './ErrorState.st.css';

interface ErrorStateProps {
  message: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Content dataHook={DataHook.ErrorState}>
      <Text tagName="h3" className={classes.title}>
        {t('app.error-state.service-not-available')}
      </Text>
      <Text tagName="p" className={classes.description}>
        {message}
      </Text>
    </Content>
  );
};
