import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Button, ButtonProps, Image, ImageFocalPointPresets, ImageResizeOptions, Text } from 'wix-ui-tpa/cssVars';

import { DataHook, Experiment, IMAGE_ROUTE } from '../../../constants';
import settingsParams, { HeaderImageCropPosition } from '../settingsParams';
import stylesParams, { PAGE_MAX_WIDTH, PageLayout } from '../stylesParams';
import { classes, style } from './Header.st.css';
import { useControllerProps } from './hooks';

type HeaderProps = {
  isLoggedIn: boolean;
  onNavigateToMyRewardsPage(): void;
  hasMyRewardsPage: boolean;
  loyaltyProgram: LoyaltyProgram;
};

const imagePositionMap = {
  [HeaderImageCropPosition.TopLeft]: ImageFocalPointPresets.topLeft,
  [HeaderImageCropPosition.Top]: ImageFocalPointPresets.topCenter,
  [HeaderImageCropPosition.TopRight]: ImageFocalPointPresets.topRight,
  [HeaderImageCropPosition.Left]: ImageFocalPointPresets.centerLeft,
  [HeaderImageCropPosition.Middle]: ImageFocalPointPresets.center,
  [HeaderImageCropPosition.Right]: ImageFocalPointPresets.centerRight,
  [HeaderImageCropPosition.BottomLeft]: ImageFocalPointPresets.bottomLeft,
  [HeaderImageCropPosition.Bottom]: ImageFocalPointPresets.bottomCenter,
  [HeaderImageCropPosition.BottomRight]: ImageFocalPointPresets.bottomRight,
};

export const Header = ({ isLoggedIn, onNavigateToMyRewardsPage, hasMyRewardsPage, loyaltyProgram }: HeaderProps) => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { myRewardsPageUrl } = useControllerProps();
  const { experiments } = useExperiments();

  const programTitleTag = settings.get(settingsParams.programTitleTag);
  const programTitle = settings.get(settingsParams.programTitle);
  const backgroundImage = settings.get(settingsParams.headerImageNew);
  const backgroundImagePosition = settings.get(settingsParams.headerImageCropPosition) as HeaderImageCropPosition;
  const buttonText = settings.get(settingsParams.buttonLoggedIn);
  const buttonTextAnonymous = settings.get(settingsParams.buttonAnonymous);
  const showProgramName = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showProgramName);
  const showProgramTitle =
    styles.getStylesForAllBreakpoints().some((breakpointStyles) => breakpointStyles.booleans.showProgramTitle) &&
    !!programTitle.trim();
  const showIcon = isMobile
    ? false
    : styles.getStylesForAllBreakpoints().some((breakpointStyles) => breakpointStyles.booleans.headerSplit);
  const showCoverImage =
    styles.getStylesForAllBreakpoints().some((breakpointStyles) => breakpointStyles.booleans.showCoverImage) &&
    backgroundImage;
  const showMainButton = hasMyRewardsPage || !isLoggedIn;
  const mainButtonHref = isLoggedIn && !!myRewardsPageUrl ? myRewardsPageUrl : '';

  const programNameTag = settings.get(settingsParams.programNameTag);
  const programName: string =
    settings.get(settingsParams.programName) || loyaltyProgram.name || t('app.settings.defaults.program-name');

  const pageLayout = styles.get(stylesParams.pageLayout);

  const headerHeight = styles.get(stylesParams.headerHeight);

  const imageMaxWidthToFetch = PAGE_MAX_WIDTH;
  const imageMaxHeightToFetch =
    pageLayout === PageLayout.Side
      ? 1517 // arbitrary max value from testing (more WTE -> more height)
      : isMobile
      ? 610 // arbitrary max value from testing
      : headerHeight;

  const imageOptimizationEnabled = experiments.enabled(Experiment.OptimizedHeaderImage);

  const handleMainButtonClick: ButtonProps['onClick'] = (event) => {
    event.preventDefault();
    onNavigateToMyRewardsPage();
  };

  return (
    <div
      data-hook={DataHook.Header}
      className={style(classes.root, {
        isMobile,
      })}
    >
      {showCoverImage && imageOptimizationEnabled && (
        <Image
          className={classes.backgroundImage}
          data-hook={DataHook.CoverImage}
          src={backgroundImage}
          focalPoint={imagePositionMap[backgroundImagePosition]}
          resize={ImageResizeOptions.cover}
          shouldUseLQIP
          encoding="AVIF"
          fluid
          sourceWidth={imageMaxWidthToFetch}
          sourceHeight={imageMaxHeightToFetch}
        />
      )}
      {showCoverImage && !imageOptimizationEnabled && (
        <Image
          data-hook={DataHook.CoverImage}
          className={classes.backgroundImage}
          src={backgroundImage}
          focalPoint={imagePositionMap[backgroundImagePosition]}
          resize={ImageResizeOptions.cover}
          fluid
        />
      )}
      <div className={classes.content}>
        {showIcon && (
          <img
            alt=""
            aria-hidden
            className={classes.icon}
            src={IMAGE_ROUTE(loyaltyProgram.pointDefinition?.icon?.url ?? '')}
          />
        )}
        {showProgramName && (
          <Text tagName={programNameTag} className={classes.title} data-hook={DataHook.ProgramName}>
            {programName}
          </Text>
        )}
        {showProgramTitle && (
          <Text tagName={programTitleTag} className={classes.subtitle} data-hook={DataHook.ProgramTitle}>
            {programTitle}
          </Text>
        )}

        {showMainButton && (
          <Button
            upgrade
            data-hook={DataHook.MainActionButton}
            className={classes.button}
            onClick={handleMainButtonClick}
            {...(!!mainButtonHref && {
              as: 'a',
              href: mainButtonHref,
            })}
          >
            {isLoggedIn ? buttonText : buttonTextAnonymous}
          </Button>
        )}
      </div>
    </div>
  );
};
