import { Status } from '@wix/ambassador-loyalty-v1-tier/types';
import { useStyles } from '@wix/tpa-settings/react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';

import { DataHook } from '../../../constants';
import { useNumberFormatter } from '../../../hooks';
import { EmptyState } from './EmptyState';
import { ErrorState } from './ErrorState';
import { MainProgram } from './MainProgram';
import { WidgetContextProvider } from './providers';
import { TiersProgram } from './TiersProgram';
import { ControllerProps } from './types';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const styles = useStyles();
  const {
    errorMessage,
    tabState,
    loyaltyProgram,
    isLoggedIn,
    onNavigateToMyRewardsPage,
    onFollowSocialMediaChannel,
    hasMyRewardsPage,
    simpleRules,
    simpleRewards,
    tiersProgramSettings,
    simpleTiers,
    isProgramAvailable,
    locale,
    currentTierId,
    followedChannels,
  } = props;
  const { formatNumber } = useNumberFormatter(locale);

  if (errorMessage) {
    return <ErrorState message={errorMessage} />;
  }

  if (!isProgramAvailable) {
    return <EmptyState />;
  }

  const showTiers =
    styles.getStylesForAllBreakpoints().some((breakpointStyles) => breakpointStyles.booleans.showTiers) &&
    tiersProgramSettings?.status === Status.ACTIVE;

  return (
    <WidgetContextProvider controllerProps={props}>
      <div data-hook={DataHook.LoyaltyWidget}>
        <MainProgram
          {...{
            tabState,
            loyaltyProgram,
            isLoggedIn,
            onNavigateToMyRewardsPage,
            hasMyRewardsPage,
            simpleRules,
            simpleRewards,
            showTiers,
            onFollowSocialMediaChannel,
            followedChannels,
          }}
        />
        {showTiers && (
          <TiersProgram
            {...{
              simpleTiers,
              simpleRules,
              simpleRewards,
              tiersProgramSettings,
              formatNumber,
              currentTierId,
              isLoggedIn,
              onFollowSocialMediaChannel,
              followedChannels,
            }}
          />
        )}
      </div>
    </WidgetContextProvider>
  );
};

export default Widget;
